<template>
  <header class="content-header" :class="{ 'content-header-scrolled': isScrolled }">
    <div class="content-header-itens">
      <router-link tag="a" :to="{ name: 'home' }" class="content-header-itens--logo">
        <img class="img-fluid" src="@/assets/img/logo-grupo-nabeiro.png" alt="logo grupo nabeiro"
          title="logo grupo nabeiro" />
      </router-link>
      <div class="content-header-itens-links d-none d-lg-flex">
        <router-link tag="a" v-if="campaigns" :to="{ name: 'ranking', params: { slug: campaigns.length > 0 && campaigns[0].slug } }"
          class="content--link v-content-header" v-contentHeader="{ section: 'Menu', removeHtml: true }">
          Ranking
        </router-link>
        <router-link tag="a" v-else :to="{ name: 'ranking'}"
          class="content--link v-content-header" v-contentHeader="{ section: 'Menu', removeHtml: true }">
          Ranking
        </router-link>
        <router-link tag="a" :to="{ name: 'account' }" class="content--link v-content-header"
          v-contentHeader="{ section: 'Menu', removeHtml: true }">
          A minha conta
        </router-link>
        <button class="btn--red" @click="scrollToElement()">
          <p class="v-content-header" v-contentHeader="{ section: 'Menu', removeHtml: true }">Submeter Oportunidade</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9">
            <path d="M4.5,0,9,8H0Z" transform="translate(8) rotate(90)" />
          </svg>
        </button>
      </div>
    </div>
  </header>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import store from '@/store';
import axios from 'axios';

const allContents = [];
export default {
  data() {
    return {
      campaigns: [],
    }
  },
  computed: {
    ...mapState('App', ['loading']),
  },
  directives: {
    contentHeader: {
      inserted: async function (el, binding) {
        const allElements = document.querySelectorAll('.v-content-header');
        let params = binding.value ? binding.value : {};
        params.content = el.innerHTML.trim();
        (!('type' in params) ? params.type = 'text' : null);
        params.theme = process.env.VUE_APP_THEME;
        params.url_full = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`;
        allContents.push(params);

        if (allElements.length == allContents.length) {
          store.dispatch('App/loading', true);
          await axios({
            method: "POST",
            url: '/api/v1/site/get-all-contents',
            data: { allContents }
          })
            .then(response => response.data)
            .then((data) => {

              const allElements = document.querySelectorAll('.v-content-header');
              allContents.forEach((el, index) => {
                if (el.type == 'text') {
                  allElements.forEach((el, index) => {
                    el.innerHTML = data.data[index];
                  });
                }
              });
              store.dispatch('App/loading', false);
            });
        }
      },

    }
  },
  props: {
    isScrolled: Boolean,
  },
  methods: {
    scrollToElement() {
      if (this.$route.name !== 'home') {
        localStorage.setItem('scrollToElement', true);
        window.location.href = '/';
      }
      setTimeout(() => {
        this.$scrollTo('#form-opportunity', 800, { easing: 'linear' });
      }, 1000);
    },
    async listCampaigns() {
      await axios({
        method: 'GET',
        url: '/api/v1/campaign/list-all',
        params: {
          is_active: 1
        }
      }).then(response => response.data.data)
        .then((data) => {
          this.campaigns = data;
        })
    },
  },
  async mounted() {
    await this.listCampaigns();
  }

}
</script>

<style lang="scss">
@import './index.scss';
</style>
