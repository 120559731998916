<template>
  <div id="app" @scroll="handleScroll()">
    <loading :active.sync="loading" :is-full-page="true"></loading>

    <HeaderApp v-if="['login', 'register', 'my-data', 'recupery-password', 'dev'].indexOf(this.$route.name) === -1" :isScrolled="isScrolled" :pathName="$route.name" />

    <transition name="fade" mode="out-in">
    	<router-view/>
	  </transition>

    <template v-if="['login', 'register', 'my-data', 'recupery-password','create-account', 'dev'].indexOf(this.$route.name) === -1">
      <MenuApp />
      <FooterApp />
    </template>
    
    <!-- MODALS -->
    <ModalCookies/>
    <ModalPolity/>
    <ModalTerms/>


    <notifications  position="top center"/>

    <div :class="{'backdrop__bar':true, 'd-none': !backdrop}"></div>

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapGetters, mapActions } from 'vuex';
import HeaderApp from '@/components/Header';
import FooterApp from '@/components/Footer';
import MenuApp from '@/components/Menu';
import ModalCookies from '@/components/Modals/ModalCookies';
import ModalPolity from '@/components/Modals/ModalPolity';
import ModalTerms from '@/components/Modals/ModalTerms';

export default {
  name: 'App',
  data() {
    return {
      isScrolled: false,
      root: null,
      pathName: null,
    }
  },
  components: {
    Loading,
    HeaderApp,
    FooterApp,
    MenuApp,
    ModalCookies,
    ModalPolity,
    ModalTerms
  },
  computed: {
    ...mapGetters('App', ['loading', 'backdrop']),
    ...mapGetters('User', ['user']),
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('User', ['getUser']),
    handleScroll(event) {
      this.isScrolled = event.currentTarget.scrollY > 100 ? true : false;
    }
  },
  mounted(){
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
    document.head.appendChild(recaptchaScript);

    if(localStorage.getItem('maishoreca_token') && Object.keys(this.user).length == 0){
      this.getUser();
    }

    if(!this.user){
      console.log('Não tem user');
      this.$router.push({ name: 'login' });
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/generic/variables.scss';
@import '@/assets/scss/generic/mixins.scss';

* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
}
body, #app {
  font-family: proxima-nova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--black);
}
input,select,button, textarea {
  outline: none;
}
.btn--black, .btn--red {
  width: 100%;
  max-width: 275px;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin: 0 auto;
  transition: 500ms;
  @media (max-width: 991px){
    max-width: 100%;
  }
  p {
    font-size: 13px;
    font-weight: var(--font-weight-bold);
    color: var(--white);
    text-transform: uppercase;
    margin-bottom: 0;
  }
}
.btn--black {
  background: var(--black);
  border: 1px solid var(--black);
  svg {
    fill: var(--red);
    transition: 500ms;
  }
  &:hover,&:focus {
    cursor: pointer;
    background: var(--white);
    p {
      color: var(--black);
    }
    svg {
      fill: var(--black);
    }
  }
}
.btn--red {
  background: var(--red-medium);
  border: 1px solid var(--red-medium);
  svg {
    fill: var(--white);
    transition: 500ms;
  }
  &:hover,&:focus {
    cursor: pointer;
    background: var(--white);
    p {
      color: var(--red-medium);
    }
    svg {
      fill: var(--red-medium);
    }
  }
}
.btn--link {
  display: block;
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  color: var(--black) !important;
  text-transform: uppercase;
  transition: 500ms;
  &:hover,&:focus {
    cursor: pointer;
    opacity: 0.8;
  }
}
.content-input {
  position: relative;
  margin-bottom: 30px;
  @media (max-width: 991px){
    margin-bottom: 15px;
  }
  input {
    width: 100%;
    height: 55px;
    background: var(--white);
    border: 1px solid var(--gray-light);
    padding: 0px 15px;
    font-size: 16px;
    font-weight: var(--font-weight-regular);
    color: var(--black);
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: var(--gray);
      font-size: 16px;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: var(--gray);
      font-size: 16px;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: var(--gray);
      font-size: 16px;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: var(--gray);
      font-size: 16px;
    }
    &:read-only {
      background: #F9F9F9;
      border: 0;
      color: var(--gray);
    }
  }
  textarea {
    display: block;
    width: 100%;
    min-height: 255px;
    background: var(--white);
    border: 1px solid var(--gray-light);
    padding: 20px 15px;
    font-size: 16px;
    font-weight: var(--font-weight-regular);
    color: var(--black);
    resize: none;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: var(--gray);
      font-size: 16px;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: var(--gray);
      font-size: 16px;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: var(--gray);
      font-size: 16px;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: var(--gray);
      font-size: 16px;
    }
  }
  &-select {
    position: relative;
    margin-bottom: 30px;
    @media (max-width: 991px){
      margin-bottom: 15px;
    }
    select {
      width: 100%;
      height: 55px;
      background: var(--white);
      border: 1px solid var(--gray-light);
      padding: 0px 30px 0px 15px;
      font-size: 16px;
      font-weight: var(--font-weight-regular);
      color: var(--gray);
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      pointer-events: none;
    }
  }
  &-check {
    display: flex;
    max-width: 400px;
    margin: 0 auto 35px auto;
    @media (max-width: 991px){
      margin: 25px auto 35px auto;
    }
    input[type="checkbox"]:checked:before {
      content: "";
      display: block;
      background: url("~@/assets/img/check.png");
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25px;
    }
    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }
    input[type="checkbox"] {
      position: relative;
      width: 20px;
      height: 20px;
      border: 1px solid #C7C7C7;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: 0 !important;
    }
    label {
      width: calc(100% - 35px);
      font-size: 12px;
      color: #565656;
      font-weight: var(--font-weight-regular);
      text-align: left;
      margin-left: 15px;
      margin-bottom: 0px;
      a {
        display: block;
        text-decoration: underline !important;
        text-decoration-color: #565656 !important;
        transition: 500ms;
        &:hover,&:focus {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }
  label {
    width: 100%;
    font-size: 12px;
    color: #565656;
    font-weight: var(--font-weight-regular);
    text-align: left;
    margin-bottom: 0px;
  }
}
.backdrop_bar {
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
}
.overflow-hidden {
  overflow: hidden;
}
#modal-collaborator, #modal-cookies, #modal-forward, #modal-polity, #modal-terms {
	.modal-header, .modal-footer {
		display: none;
	}
	.modal-dialog {
		max-width: 750px;
	}
	.modal-content {
		border-radius: 6px;
		box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
		border: 0;
	}
	.modal-body {
		padding: 0;
	}
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0
}
.notification-content{
  font-size: 18px;
}
.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
.recaptcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.page-link {
  cursor: pointer;
}
</style>
