import axios from 'axios';

export default {
    logoff(context) {
        context.commit('setToken', '');
        context.commit('setUser', {});
        localStorage.removeItem('maishoreca_token');
    },
    async getUser(context) {
        context.commit('App/loading', true, {
            root: true
        });

        return new Promise((resolve, reject) => {
            axios({
                    method: 'GET',
                    url: '/api/v1/campaign/user/info',
                }).then(response => response.data.data)
                .then((data) => {
                    console.log(data);
                    context.commit('setUser', data);
                    context.commit('App/loading', false, {
                        root: true
                    });
                    
                    resolve(data);
                }).catch(error => {
                    context.commit('App/loading', false, {
                        root: true
                    });

                    context.commit('setToken', '');
                    context.commit('setUser', {});
                    localStorage.removeItem('maishoreca_token');

                    reject(error);
                    
                    // if ('response' in error) {
                    //     if ('url' in error.response.data) {
                    //         localStorage.removeItem('maishoreca_token');
                    //         window.location.href = '/';
                    //     }
                    // }
                });
        });
    },
    async setUser(context, payload) {
        context.commit("setUser", payload);
    },
    async setToken(context, payload) {
        context.commit("setToken", payload);
    },
};
