<template>
  <footer class="content-footer">
    <div class="content-footer-itens container">
      <div class="row">
        <div class="col-lg-6">
          <div class="content-footer-itens-element">
            <div class="content-footer-itens-element-header">
              <p>contactos</p>
            </div>
            <div class="content-footer-itens-element--links">
              <!-- <a href="tel:351808000000">808 00 00 00</a> -->
              <a href="mailto:clientesamigos@gruponabeiro.com" target="_blank" rel="noopener">clientesamigos@gruponabeiro.com</a>
            </div>
          </div>
        </div>
      </div>
      <div class="content-footer-itens-copyright">
        <div class="content-footer-itens-copyright--text">
          <p>©  {{ (new Date().getFullYear()) }} Grupo Nabeiro. Todos os direitos reservados.</p>
        </div>
        <div class="content-footer-itens-copyright--links">
          <a href="/storage/app/media/Delta/politica-privacidade-clientes-amigos.pdf" target="_blank" rel="noopener">Política de Privacidade</a>
          <a href="/storage/app/media/Delta/regulamento-movimento-clientes-amigos.pdf" target="_blank" rel="noopener">Termos e Condições</a>
          <a href="/storage/app/media/Delta/politica-de-cookies-clientes-amigos.pdf" target="_blank" rel="noopener">Política de Cookies</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@import './index.scss';
</style>
