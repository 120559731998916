import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    loading: false,
    error: {},
    alert: {},
    modalAlert: {},
    pusher: null,
    configs: {},
    recaptchaKey: '6LehbsIlAAAAAKxpODmzjtQIuwHatg8nftbeutIb',
    backdrop: false,
    menuNavOpen: false,
  },
  actions,
  getters,
  mutations,
};