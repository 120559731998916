<template>
  <b-modal id="modal-terms" centered class="modal-default" @show="updateDirectives">
    <div class="modal-default-itens">
      <div class="modal-default-itens-header">
        <button class="modal-default-itens-close" @click="$bvModal.hide('modal-terms')">
          <p>X</p>
        </button>
      </div>
      <div class="mask" id="maskTerms"></div>
      <div class="modal-default-itens-body">
        <div class="modal-default--header">
          <h2 class="v-content-terms" v-contentTerms="{ section: 'Modal Termos e Condições', removeHtml: true }">Termos e
            Condições</h2>
        </div>
        <div class="modal-default--text">
          <p class="v-content-terms" v-contentTerms="{ section: 'Modal Termos e Condições' }">Lorem ipsum dolor sit amet,
            consectetur adipiscing elit.
            Sed ut lectus lectus. Integer suscipit nunc lacus, ut tempor justo faucibus quis. Duis rhoncus eget ipsum in
            maximus. Etiam ultrices tortor sed ante convallis, quis dignissim est commodo. Nullam vulputate dolor et diam
            tincidunt, at viverra quam fermentum. Suspendisse potenti. Cras lectus justo, aliquam et elementum eu, tempor
            eu nisi. Aliquam vitae sapien at lorem venenatis efficitur a non mauris.</p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import store from '@/store';
import axios from 'axios';

const allContents = [];
export default {
  data() {
    return {
    }
  },
  created() {
    this.updateDirectives();
  },
  methods: {
    updateDirectives() {
      const allElements = document.querySelectorAll('.v-content-terms');
      allContents.splice(0, allContents.length); // clear the contents array
      allElements.forEach((el) => {
        const params = el.dataset.params ? JSON.parse(el.dataset.params) : {};
        params.content = el.innerHTML.trim();
        (!('type' in params) ? params.type = 'text' : null);
        params.theme = process.env.VUE_APP_THEME;
        params.url_full = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`;
        allContents.push(params);
      });
    },
  },
  directives: {
    contentTerms: {
      inserted: async function (el, binding) {
        const allElements = document.querySelectorAll('.v-content-terms');
        let params = binding.value ? binding.value : {};
        params.content = el.innerHTML.trim();
        (!('type' in params) ? params.type = 'text' : null);
        params.theme = process.env.VUE_APP_THEME;
        params.url_full = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}`;
        allContents.push(params);

        if (allElements.length == allContents.length) {
          store.dispatch('App/loading', true);
          await axios({
            method: "POST",
            url: '/api/v1/site/get-all-contents',
            data: { allContents }
          })
            .then(response => response.data)
            .then((data) => {

              const allElements = document.querySelectorAll('.v-content-terms');
              allContents.forEach((el, index) => {
                if (el.type == 'text') {
                  allElements.forEach((el, index) => {
                    el.innerHTML = data.data[index];
                  });
                }
              });
              store.dispatch('App/loading', false);
              document.getElementById('maskTerms').remove();
            });
        }
      },

    }
  },
}
</script>

<style lang="scss" scoped>
@import '../index.scss';
.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
  top: 0;
  left: 0;
}
</style>
