import Vue from 'vue';
import VueRouter from 'vue-router';
import {protectedPage} from '@/utils';

Vue.use(VueRouter);


const baseRoutes = [
	{
		path: '/',
		name: 'home',
		beforeEnter(to, from, next){
	    	protectedPage(to, from, next);
	  	},
		component: () => import(/* webpackChunkName: "home" */'@/views/Home'),
	},
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */'@/views/Login'),
	},
	{
		path: '/register',
		component: () => import(/* webpackChunkName: "register" */ '../views/Register'),
		children: [
		  {
			path: '',
			name: 'register',
			component: () => import(/* webpackChunkName: "register" */ '../views/Register/CreateAccount')
		  },
		  {
			path: 'create-account',
			name: 'create-account',
			component: () => import(/* webpackChunkName: "register" */ '../views/Register/CreateAccount/form2.vue')
		  },
		  {
			path: 'my-data',
			name: 'my-data',
			component: () => import(/* webpackChunkName: "register" */ '../views/Register/MyData')
		  },
		]
	},
	{
		path: '/recupery-password',
		name: 'recupery-password',
		component: () => import(/* webpackChunkName: "recupery-password" */ '../views/RecuperyPassword')
	},
	{
		path: '/account',
		name: 'account',
		component: () => import(/* webpackChunkName: "account" */ '../views/Account'),
		beforeEnter(to, from, next){
			protectedPage(to, from, next);
		},
	},
	{
		path: '/ranking/:slug',
		name: 'ranking',
		component: () => import(/* webpackChunkName: "ranking" */ '../views/Ranking'),
		beforeEnter(to, from, next){
			protectedPage(to, from, next);
		},
	},
	{
		path: '/campaigns',
		name: 'campaigns',
		component: () => import(/* webpackChunkName: "login" */'@/views/Campaigns'),
	},
	{
		path: '/campaign/:slug',
		name: 'campaign',
		component: () => import(/* webpackChunkName: "campaign" */'@/views/Campaigns/Detail.vue'),
	},
	{
		path: '/my-campaigns',
		name: 'my-campaigns',
		beforeEnter(to, from, next){
	      protectedPage(to, from, next);
	    },
		component: () => import(/* webpackChunkName: "my-campaigns" */'@/views/MyCampaigns'),
	},
	{
		path: '/dev',
		name: 'dev',
		component: () => import(/* webpackChunkName: "404" */'@/views/Dev'),
	},
	{
		path: '*',
		name: '404',
		component: () => import(/* webpackChunkName: "404" */'@/views/404'),
	},
];

const routes = baseRoutes;

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior (to, from, savedPosition) {
	    return { x: 0, y: 0 };
	  }
});

export default router;
